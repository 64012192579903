import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import NewsletterForm from '../components/NewsletterForm'

class Contact extends React.Component {
  render () {
    return (
      <Layout>
        <SEO title='Contact' pathname='contact' />
        <div className='contact'>
          <div className='container'>
            <h1 style={{ textAlign: 'center', fontFamily: 'Playfair' }}>Let's keep in Touch!</h1>
            <p>
              Thank you so much for reading my content, I love making it and I
              hope it's helpful. Let's keep the conversation going!
            </p>
            <p>
              If you have feedback on the site or a post, please{' '}
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://twitter.com/aspittel'
              >
                tweet or dm me!
              </a>
            </p>
            <p>
              If you're interested in partnership opportunities or having me
              speak at an event, please email{' '}
              <a href='mailto:hello@welearncode.com'>hello@welearncode.com</a>
            </p>
            <NewsletterForm />
          </div>
        </div>
      </Layout>
    )
  }
}

export default Contact
